/* eslint-disable react-hooks/exhaustive-deps */
import { PageTitle } from "../style";
// import SummarySection from "./SummarySection";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import {
  getHostelBlocks,
  getRoomBunks,
  getStudentsHostelAllocation,
} from "../../../Apis/Private/hostel";
import HostelAllocationTable from "../../../Tables/AllHostels/HostelAllocationTablr";
import AllocationFilter from "../../../Components/Filters/AllocationFilter";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import { toast } from "react-toastify";

const HostelAllocation = () => {
  const {
    HostelId,
    SemesterId,
    BlockId,
    LevelId,
    GenderId,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    StudentType,
    SearchPrams,
    BunkId,
  } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getHostelsFormDetails({ name, value }));
  };

  const handleGettingHostelAllocation = () => {
    dispatch(
      getHostelsFormDetails({ name: "studentsHostelAllocation", value: null })
    );
    if ((SessionId || SearchPrams) && HostelId && BlockId && BunkId) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    } else {
      toast.info(
        "At least Hostel, Block, Bunk, and  Session must be selected to filter"
      );
    }
  };

  useEffect(() => {
    getRoomBunks(BlockId, HostelId);
    getHostelBlocks(HostelId);
  }, [HostelId, BlockId]);

  useEffect(() => {
    if (SearchPrams) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    }
    if (SessionId || SearchPrams) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    }
  }, [SearchPrams, PageSize, PageNumber]);

  return (
    <>
      <PageTitle>Hostel Allocations</PageTitle>
      <OutlinedSearchInput
        placeholder="Search Student"
        name="SearchPrams"
        value={SearchPrams}
        onChangeHandler={handleOnChange}
        searchFunction={getHostelsFormDetails}
      />
      <AllocationFilter filterFunction={handleGettingHostelAllocation} />
      <HostelAllocationTable />
    </>
  );
};

export default HostelAllocation;
