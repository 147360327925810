import { AiOutlineUpload } from "react-icons/ai";
import { BiSolidReport } from "react-icons/bi";
import { BsHouse, BsHouseAddFill, BsPersonFillCheck } from "react-icons/bs";
import { CiNoWaitingSign } from "react-icons/ci";
import { FaCashRegister, FaDoorClosed, FaRegEdit } from "react-icons/fa";
import {
  IoCloudDownloadSharp,
  IoSettingsOutline,
  IoSettingsSharp,
} from "react-icons/io5";
import {
  MdBedroomParent,
  MdHouseSiding,
  MdOutlineDashboard,
  MdOutlineIncompleteCircle,
  MdOutlinePayment,
  MdOutlinePendingActions,
  MdOutlineVerified,
} from "react-icons/md";
import { PiMoneyLight, PiStepsBold, PiStudentDuotone } from "react-icons/pi";
import { RiMindMap } from "react-icons/ri";
import { TbClearAll } from "react-icons/tb";

export const NavItems = [
  {
    id: "1",
    parentName: "Dashboard",
    parentLink: "/",
    permission: "dashboard",
    hasChildren: false,
    parentIcon: <MdOutlineDashboard />,
  },
  {
    id: "2",
    parentName: "Students",
    parentLink: "/Students",
    hasChildren: true,
    Children: [
      {
        id: "2-1",
        childName: "Report",
        childLink: "/Students",
        childIcon: <PiStudentDuotone />,
        permission: "view-students",
      },
      {
        id: "2-2",
        childName: "course Registration",
        childLink: "/Students/course-registration",
        childIcon: <FaRegEdit />,
        permission: "course-reg",
      },
    ],
  },
  {
    id: "7",
    parentName: "Admission",
    parentLink: "/admission",
    hasChildren: true,
    Children: [
      {
        id: "7-1",
        childName: "Pending",
        childLink: "/admission/pending-admissions",
        childIcon: <MdOutlinePendingActions />,
        permission: "view-admission",
      },
      {
        id: "7-2",
        childName: "Admitted",
        childLink: "/admission/admitted-students",
        childIcon: <BsPersonFillCheck />,
        permission: "view-admission",
      },
      {
        id: "7-3",
        childName: "Initiated",
        childLink: "/admission/initiated-admissions",
        childIcon: <MdOutlineIncompleteCircle />,
        permission: "view-admission",
      },
      {
        id: "7-4",
        childName: "Register Student",
        childLink: "/application",
        childIcon: <FaCashRegister />,
        permission: "register-student",
      },
    ],
  },
  {
    id: "3",
    parentName: "Payments",
    parentLink: "/payments",
    hasChildren: true,
    Children: [
      {
        id: "3-1",
        childName: "Report",
        childLink: "/payments",
        childIcon: <MdOutlinePayment />,
        permission: "view-payments",
      },
      {
        id: "3-2",
        childName: "Verify Payments",
        childLink: "/payments/verify-payments",
        childIcon: <MdOutlineVerified />,
        permission: "verify-payments",
      },
      {
        id: "3-3",
        childName: "Upload Offline Payment",
        childLink: "/payments/upload-offline-payment",
        childIcon: <AiOutlineUpload />,
        permission: "upload-offline-payments",
      },
    ],
  },
  {
    id: "4",
    parentName: "Hostel",
    parentLink: "/hostels",
    hasChildren: true,
    Children: [
      {
        id: "4-1",
        childName: "View Hostels",
        childLink: "/hostels",
        childIcon: <MdHouseSiding />,
        permission: "view-hostel",
      },
      {
        id: "4-2",
        childName: "Hostel Session",
        childLink: "/hostel/view-hostel-session",
        childIcon: <PiStepsBold />,
        permission: "hostel-settings",
      },
      {
        id: "4-8",
        childName: "Copy Hostel Session",
        childLink: "/settings/hostel-setting",
        childIcon: <BsHouse />,
        permission: "hostel-settings",
      },
      {
        id: "4-3",
        childName: "Hostel Blocks",
        childLink: "/hostel/view-hostel-blocks",
        childIcon: <FaDoorClosed />,
        permission: "hostel-settings",
      },
      {
        id: "4-4",
        childName: "Hostel Block Rooms",
        childLink: "/hostel/view-block-rooms",
        childIcon: <MdBedroomParent />,
        permission: "hostel-settings",
      },
      {
        id: "4-5",
        childName: "Bunk Settings",
        childLink: "/hostel/bunk-settings",
        childIcon: <IoSettingsSharp />,
        permission: "hostel-settings",
      },
      {
        id: "4-6",
        childName: "Report",
        childLink: "/hostel/allocation-report",
        childIcon: <BiSolidReport />,
        permission: "hostel-report",
      },
      {
        id: "4-7",
        childName: "Allocate Hostel",
        childLink: "/hostel/allocate-hostel",
        childIcon: <BsHouseAddFill />,
        permission: "hostel-settings",
      },
    ],
  },
  {
    id: "5",
    parentName: "Settings",
    parentLink: "/settings",
    hasChildren: true,
    Children: [
      {
        id: "5-1",
        childName: "Fee Mapping",
        childLink: "/settings/fee-mapping",
        childIcon: <RiMindMap />,
        permission: "fee-setting",
      },
      {
        id: "5-2",
        childName: "Exam Clearance",
        childLink: "/settings/exam-clearance",
        childIcon: <TbClearAll />,
        permission: "exam-clearance",
      },
      {
        id: "5-3",
        childName: "Deferment Settings",
        childLink: "/settings/deferment",
        childIcon: <CiNoWaitingSign />,
        permission: "deferment-settings",
      },
      {
        id: "5-4",
        childName: "Set Student Level",
        childLink: "/settings/set-student-level",
        childIcon: <PiStudentDuotone />,
        permission: "set-student-level",
      },
      {
        id: "5-5",
        childName: "Set Session",
        childLink: "/settings/set-session",
        childIcon: <PiStepsBold />,
        permission: "set-session",
      },

      {
        id: "5-7",
        childName: "Fee Schedule",
        childLink: "/settings/fee-schedule",
        childIcon: <PiMoneyLight />,
        permission: "set-fee-schedule",
      },
      {
        id: "5-6",
        childName: "Other Settings",
        childLink: "/settings/profile",
        childIcon: <IoSettingsOutline />,
        permission: "other-settings",
      },
    ],
  },
  {
    id: "6",
    parentName: "Exports",
    parentLink: "/exports",
    hasChildren: false,
    parentIcon: <IoCloudDownloadSharp />,
    permission: "view-exports",
  },
];
