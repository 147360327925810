import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  ItemsCenterJustifyBtw,
  PageTitle,
} from "../style";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { copyAndSetHostelSettings } from "../../../Apis/Private/hostel";

const HostelSettings = () => {
  const {
    oldSemesterId,
    newSemesterId,
    oldSessionId,
    newSessionId,
    copyingHostelSettings,
  } = useAppSelector(selectHostel);
  const { sessions, semesters } = useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };

  const handleSubmit = async () => {
    await copyAndSetHostelSettings(
      newSessionId,
      oldSessionId,
      newSemesterId,
      oldSemesterId
    );
  };

  return (
    <>
      <PageTitle>Copy Hostel Settings</PageTitle>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <PageTitle>Source</PageTitle>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                dropdownItems={sessionFilter}
                handleDropdownSelect={handleSelect}
                name="oldSessionId"
                title="Session"
                value={oldSessionId}
              />
              <UnderlinedSelector
                dropdownItems={semesterFilter}
                handleDropdownSelect={handleSelect}
                name="oldSemesterId"
                title="Semester"
                value={oldSemesterId}
              />
            </ItemsCenterJustifyBtw>
            <PageTitle>Destination</PageTitle>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                dropdownItems={sessionFilter}
                handleDropdownSelect={handleSelect}
                name="newSessionId"
                title="Session"
                value={newSessionId}
              />
              <UnderlinedSelector
                dropdownItems={semesterFilter}
                handleDropdownSelect={handleSelect}
                name="newSemesterId"
                title="Semester"
                value={newSemesterId}
              />
            </ItemsCenterJustifyBtw>
            <BigButton
              buttonText="Submit"
              textTransform="capitalize"
              disabled={
                !oldSessionId ||
                !oldSemesterId ||
                !newSemesterId ||
                !newSessionId ||
                copyingHostelSettings
              }
              loading={copyingHostelSettings}
              handleClick={handleSubmit}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default HostelSettings;
